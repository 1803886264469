// Rename to Services.jsx
export const Services = (props) => {
  return (
    <div id="services">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>{props.data ? props.data.title : "Loading"}</h2>
          <p>{props.data ? props.data.paragraph : "Loading"}</p>
        </div>
        <div className="services-cards">
          {props.data
            ? props.data.items.map((d, i) => (
              <div key={`${d.title}-${i}`} className="service-card">
                <i className={d.icon}></i>
                <h3>{d.title}</h3>
                <p>{d.text}</p>
              </div>
            ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};